export const findPaymentOption = (paymentoptionlist, userdata, regionlist) => {
  // Step 1: Filter and sort the payment options based on region and agency
  // console.log(paymentoptionlist);
  // console.log(regionlist);
  // console.log(userdata);

  // Step 1a:Check regionlist, where region_code equals region_code of the logged in user

  let regionid = regionlist?.find(
    (region) => region.region_code === userdata?.region_code
  )?.id;

  // Step 2: Check Paymentoptions, where region_id equals id of the logged in region

  // console.log(regionid);
  let sortedpaymentList = paymentoptionlist?.filter(
    (item) => item.region_id === regionid
  );

  // Step 3: Check for an occurrence where agency_id equals AID
  const occurrence = sortedpaymentList?.find(
    (item) => item.agency_id === userdata?.ID
  );

  // Step 4: If an occurrence is found, return the required object
  if (occurrence) {
    return {
      payment_mode: occurrence.payment_mode,
      paymentprovider_id: occurrence.paymentprovider_id,
    };
  }

  // Step 5: If no occurrence is found, check for the item where agency_id equals 0
  const defaultOccurrence = sortedpaymentList?.find(
    (item) => item.agency_id === 0
  );

  // Step 6: If found, return the object for that item
  if (defaultOccurrence) {
    return {
      payment_mode: defaultOccurrence.payment_mode,
      paymentprovider_id: defaultOccurrence.paymentprovider_id,
    };
  }

  // Step 7: If neither is found, return null or an empty object
  return null;
};
