import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { NodeServer_API } from "../server";
import Loader from "../Components/Loader/Loading";

const ActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(0);

  useEffect(() => {
    if (activation_token) {
      const sendRequest = async () => {
        await axios
          .post(`${NodeServer_API}/agents/activation`, {
            token: activation_token,
          })
          .then((res) => {
            console.log(res);
            setError(1);
          })
          .catch((err) => {
            setError(2);
          });
      };
      sendRequest();
    }
  }, []);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center gap-3 relative">
      {error === 0 && <Loader type="spin" text="Loading..." />}
      {error === 2 ? (
        <p>Your token is expired!</p>
      ) : (
        error === 1 && <p>Your account has been created suceessfully!</p>
      )}

      <Link to="/login">
        <button className="text-[14px] text-[#fff] p-2 bg-[green] rounded-sm">
          Sign In
        </button>
      </Link>
    </div>
  );
};

export default ActivationPage;
