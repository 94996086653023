import React, { useState, useEffect } from "react";
import "./page.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { BiGift } from "react-icons/bi";
import { FaRegMoneyBillAlt, FaSuitcase } from "react-icons/fa";
import { IoChevronDown } from "react-icons/io5";

// import SLoader from "../Components/Loader/SigninLoader";
import ButtonPagenate from "../Components/ButtonPagenate/ButtonPagenate";
import Currencycode from "../Components/Card/currencycode";
// import Error from "../Components/Modal/Error";
import { NodeServer_API } from "../server";
import { Store } from "../Redux/store";
import { claimsRecord, transactionRecord } from "../Redux/Actions/dataResponse";
import formatDateTime from "../Utils/Datefunction";

const Points = ({ changed, respmessage }) => {
  const { t } = useTranslation();
  const {
    userdata,
    pocketdata,
    pointsdata,
    exchangerate,
    bankdetails,
    claimsdata,
    regionpaymentoption,
  } = useSelector((state) => state.datareponse);
  const { paystackWalletBalance } = useSelector((state) => state.paystackdata);

  // console.log(claimsdata);

  const [cookies] = useCookies(["Token"]);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState(0);
  const [accountnumber, setAccountnumber] = useState("");
  const [transferdetails, setTransferdetails] = useState({
    points: 0,
    recipientCode: "",
  });
  const [socialvalue, setSocialvalue] = useState("");
  const [social, setSocial] = useState(false);

  const itemsPerpage = 6;
  const pageCount = Math.ceil(claimsdata?.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const regionCode = userdata.region_code;
  const seerbitWalletBalance = pocketdata?.find(
    (regionpocket) => regionpocket.country === regionCode
  )?.pocketBalance;
  const currencyCode = Currencycode[regionCode];

  useEffect(() => {
    const selectedBank = bankdetails?.find(
      (bankdet) => bankdet.ID === selected
    );
    setAccountnumber(selectedBank?.accountNumber || "");
  }, [selected, bankdetails]);

  const handleClick = async (e) => {
    e.preventDefault();
    const amount =
      regionCode === "NG"
        ? exchangerate?.data?.amountPerDollar *
          parseInt(transferdetails.points) *
          100
        : exchangerate?.data?.amountPerDollar *
          parseInt(transferdetails.points);

    const objData = {
      points: transferdetails.points,
      recipientCode: transferdetails.recipientCode,
      bank: socialvalue,
      // bank:
      //   regionpaymentoption?.payment_mode === "ONLINE"
      //     ? socialvalue
      //     : "OFFLINE",
      // accountnumber:
      //   regionpaymentoption?.payment_mode === "ONLINE"
      //     ? accountnumber
      //     : "OFFLINE",
      accountnumber: accountnumber,
      amount,
      users: userdata,
      exchangerate,
    };

    if (objData.points <= 10) {
      respmessage({
        loadstatus: 1,
        loadMessage: "Processing Claims...",
        icon: "0",
      });
      changed(1);

      if (amount <= 100) {
        // mimimum withdrawable points is set to 2000 NB: Nigeria is 200000 kobo
        toast.error(`Oops! You cannot claim less than ${currencyCode} 200`);
        return respmessage({ loadstatus: 0, loadMessage: "", icon: "0" });
      } else if (amount > 2000000) {
        // 2000000 kobo
        toast.error(
          "Ooops! Unable to dispense that amount, try something lesser!"
        );
        return respmessage({ loadstatus: 0, loadMessage: "", icon: "0" });
      }

      // if attempted withdrawal is greater than wallet balance, raise a flag

      const walletBalance =
        regionCode === "NG" ? paystackWalletBalance : seerbitWalletBalance;

      // Constraint to withdraw more than the available balance
      if (amount >= walletBalance - 500) {
        toast.error("Ooops! With declined! try something lesser!");
        return respmessage({ loadstatus: 0, loadMessage: "", icon: "0" });
      }

      try {
        const config = {
          method: "POST",
          url: `${NodeServer_API}/claims/${
            regionCode === "NG" ? "team1" : "team2"
          }`,
          data: objData,
          headers: { Authorization: "Bearer " + cookies.Token },
        };

        // console.log(config);

        const response = await axios(config);
        if (response.data.success) {
          changed(1);
          respmessage({ loadstatus: 0, loadMessage: "", icon: "0" });
          Store.dispatch(
            transactionRecord(response.data.data.transactionhistory)
          );
          Store.dispatch(claimsRecord(response.data.data.claimsdata));
          toast.success(response.data.message);
          window.location.reload(); // Reloads the entire page
        } else {
          respmessage({ loadstatus: 0, loadMessage: "", icon: "0" });
          toast.error(response.data.message);
        }
      } catch (e) {
        if (e.message.includes("401")) {
          respmessage({
            loadstatus: 2,
            loadMessage: "Session Expired!",
            icon: "2",
          });
        } else {
          // console.log(e.response.data.message);
          toast.error(e.response.data.message);
          respmessage({ loadstatus: 0, loadMessage: "", icon: "0" });
        }
      }
    } else {
      toast.error(
        "Sorry! you cannot claim more than 10 Points for this testing stage"
      );
    }
  };

  const onChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setTransferdetails({
        ...transferdetails,
        points: event.target.value || "",
      });
    }
  };

  const handleSocialFocus = () => {
    setSocial(!social);
  };

  const handleSocialClick = (e, incoming) => {
    setTransferdetails({
      ...transferdetails,
      recipientCode: incoming.recipientcode,
    });
    setSelected(incoming.id);
    setSocialvalue(incoming.bankname);
    setSocial(false);
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  return (
    <div className="bnw-claim-container">
      <div className="point-redemption-container">
        <div className="redeem-point-row-1">
          <span>
            <FaRegMoneyBillAlt />
          </span>
          <span>{t("redeemypoint")}</span>
        </div>

        <div>
          <b>NB:</b> {t("claimsnotabene")}
        </div>
        <div>
          <b>NB:</b> {t("notabeneclaims")}
        </div>

        <form
          onSubmit={handleClick}
          className="w-[80%] flex flex-col gap-1 min-w-[280px]"
        >
          <div className="numberfield-container">
            <div>
              <BiGift />
            </div>
            <input
              // disabled
              required
              onChange={onChange}
              disabled={pointsdata?.available <= 0}
              value={transferdetails.points}
              type="text"
              placeholder={t("pointstowithdraw")}
              className="numberfield"
            />
          </div>

          <div className="w-full relative">
            <input
              required
              readOnly
              value={socialvalue}
              onClick={pointsdata?.available > 0 && handleSocialFocus}
              placeholder={t("selectaccount")}
              className="input_field w-full outline-none p-[10px]"
            />
            <IoChevronDown
              id="chevron-down"
              onClick={pointsdata?.available > 0 && handleSocialFocus}
            />
            {regionpaymentoption?.payment_mode === "ONLINE" ? (
              social && (
                <div
                  onMouseLeave={handleSocialFocus}
                  className="w-full absolute py-1 bg-[white] flex flex-col justify-start max-h-[150px] z-10 top-[38px] overflow-y-scroll border"
                >
                  {bankdetails?.map((item, index) => (
                    <span
                      onClick={(e) =>
                        handleSocialClick(e, {
                          id: item.ID,
                          recipientcode: item.recipientCode,
                          bankname: item.bankName,
                        })
                      }
                      key={index}
                      className="w-full p-2 hover:bg-[#ccc4] border-b"
                    >
                      {item.bankName}
                    </span>
                  ))}
                </div>
              )
            ) : (
              <div
                onMouseLeave={handleSocialFocus}
                className="w-full absolute py-1 bg-[white] flex flex-col justify-start max-h-[150px] z-10 top-[38px] overflow-y-scroll border"
              >
                OFFLINE
              </div>
            )}
          </div>

          <div className="w-full">
            <input
              disabled
              className="input_field w-full p-2 border"
              placeholder={t("accountnumber")}
              // value={
              //   regionpaymentoption?.payment_mode === "ONLINE"
              //     ? accountnumber
              //     : "OFFLINE"
              // }
              value={accountnumber}
              onChange={() => setTransferdetails({ ...transferdetails })}
            />
          </div>

          <div className="w-full flex gap-2 mt-3">
            <span>{t("pointstoremove")}</span>
            <span>
              @{currencyCode} ∼{" "}
              {exchangerate?.data.amountPerDollar *
                parseInt(transferdetails.points)}
            </span>
          </div>

          {/* disable buttton if available point is less then the minimum threshold */}
          <div className="w-full flex relative text-[#fff] gap-4 items-center justify-center overflow-hidden">
            <FaSuitcase
              color="white"
              className="absolute left-[25%]"
              size={16}
            />
            <input
              disabled={pointsdata?.available <= 0}
              // disabled
              type="submit"
              className={`p-2 text-[14px] w-full h-full ${
                pointsdata?.available <= 0
                  ? "bg-[#888]"
                  : "bg-[#e50000] cursor-pointer"
              } font-[600] rounded-[5px]`}
              value={t("cashoutpoints")}
            />
          </div>
        </form>
      </div>

      <table>
        <thead>
          <tr>
            <th scope="col">SN</th>
            <th scope="col">{t("points")}</th>
            <th scope="col">₦ {t("value@claim")}</th>
            <th scope="col">{t("status")}</th>
            <th scope="col">{t("daterequested")}</th>
          </tr>
        </thead>

        <tbody>
          {claimsdata
            .slice(pagesVisited, pagesVisited + itemsPerpage)
            .map((items, index) => (
              <tr key={index}>
                <td data-label="S/N">{index + 1}</td>
                <td data-label={t("status")}>{items.points}</td>
                <td data-label={t("daterequested")}>
                  {items.pointsEquivalent}
                </td>
                <td
                  id={
                    items.Status === 1
                      ? "row-appr-label"
                      : items.Status === 2
                      ? "row-declined-label"
                      : "row-notappr-label"
                  }
                  data-label={t("status")}
                >
                  {items.Status === 1
                    ? "Approved"
                    : items.Status === 2
                    ? "Under Review"
                    : "Not Yet Treated"}
                </td>
                <td data-label={t("daterequested")}>
                  {formatDateTime(items.UpdatedAt)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="bnw-table-pgnate-contain">
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default Points;
